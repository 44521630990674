import { ActionTree, Module, MutationTree } from 'vuex';
import { NotificationState } from '@/types/store/notificationState';
import { NotificationDetails } from '@/types/notificationDetails';
import { RootState } from '@/types/store/rootState';

const state: NotificationState = {
  notification: null
};

const mutations: MutationTree<NotificationState> = {
  setNotification(theState: NotificationState, theNotification: NotificationDetails) {
    theState.notification = theNotification;
  }
};

export const actions: ActionTree<NotificationState, RootState> = {
  show({ commit, state: theState }, theNotification: NotificationDetails): void {
    commit('setNotification', theNotification);
  },
  clear({ commit }): void {
    commit('setNotification', null);
  }
};

export const notificationStore: Module<NotificationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
