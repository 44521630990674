<template>
  <b-nav-item href="/help/57600.htm" target="_blank" v-if="isAuthenticated" :title="$t('header.help-link-title-attribute')">
    <span slot="button-content">
      <span class="sr-only">{{$t('header.screen-reader-help-link')}}</span>
      <font-awesome-icon icon="question-circle" />
      <span class="ml-1 d-md-none">{{$t('header.help')}}</span>
    </span>
  </b-nav-item>
</template>

<script lang="ts">
  import { Component, Watch, Vue } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { AuthState } from '@/types/store/authState';


  @Component
  export default class Help extends Vue {
    isAuthenticated: boolean = false;
    @State('authStore') private authState!: AuthState;

    @Watch('authState.isAuthenticated', { immediate: true })
    private onIsAuthenticatedStateChanged(state: boolean | null) {
      this.isAuthenticated = state === true ? true : false;
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
