import videojs_ from 'video.js';
import { videojs } from 'vue-video-player';

const Button = videojs.getComponent('Button');

export default class BookmarkSummary extends Button {

  static createEl(options: any): any {
    const divEl = videojs.dom.createEl('div',
      {
        // Prefixing classes of elements within a player with "vjs-"
        // is a convention used in Video.js.
        className: 'vjs-bookmark-summary'
      });

    if (options.bookmarksCount) {
      const span = videojs.dom.createEl('span',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-bookmark-summary-text'
        });

      const button = videojs.dom.createEl('button',
        {
          // Prefixing classes of elements within a player with "vjs-"
          // is a convention used in Video.js.
          className: 'vjs-button-bookmark-summary'
        });

      videojs.dom.appendContent(button, span);

      videojs.dom.appendContent(divEl, button);
    }

    return divEl;
  }
  getBookmarkSummaryCaption: () => string;
  includeCommentsAndBookmarks: boolean;
  showBookmarkCarousel: () => void;

  constructor(player: videojs_.Player, options: any) {
    options.el = BookmarkSummary.createEl(options);
    super(player, options);

    this.getBookmarkSummaryCaption = options.getBookmarkSummaryCaption;
    this.includeCommentsAndBookmarks = options.includeCommentsAndBookmarks;
    this.showBookmarkCarousel = options.showBookmarkCarousel;

    // a bit of casting for TS to find the right interface it inherits from
    const anyThis = this as any;
    const buttonThis = anyThis as videojs_.Button;

    buttonThis.on('relativePathChangedEvent', this.updateTextContent);
    this.updateTextContent();

  }

  updateTextContent() {
    let text = '';
    if (this.getBookmarkSummaryCaption instanceof Function) {
      text = this.getBookmarkSummaryCaption();
    }

    // If no text was provided, default to empty string
    if (typeof text !== 'string') {
      text = '';
    }

    // a bit of casting for TS to find the right interface it inherits from
    const anyThis = this as any;
    const buttonThis = anyThis as videojs_.Button;

    // Use Video.js utility DOM methods to manipulate the content
    // of the component's element.
    const span = videojs.dom.$('.vjs-bookmark-summary-text', buttonThis.el());
    if (!span) {
      return;
    }

    videojs.dom.insertContent(span, text);
    const button = videojs.dom.$('.vjs-button-bookmark-summary', buttonThis.el());
    if (this.includeCommentsAndBookmarks) {
      videojs.dom.addClass(button, 'vjs-menu-button');
      return;
    }
    videojs.dom.removeClass(button, 'vjs-menu-button');
  }

  handleClick() {
    if (!this.includeCommentsAndBookmarks) {
      return;
    }

    videojs.log('Navigation to the first bookmark is requested');
    this.showBookmarkCarousel();
  }

}

videojs.registerComponent('bookmarkSummary', BookmarkSummary);
