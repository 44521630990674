<template>
  <div id="app" v-if="showMarkup">
    <Header />
    <div class="container">
      <main role="main">
        <router-view />
      </main>
    </div>
    <NotificationsModal></NotificationsModal>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import Header from '@/components/Header.vue'; // @ is an alias to /src
  import NotificationsModal from '@/components/NotificationsModal.vue'; // @ is an alias to /src
  import { Route } from 'vue-router';

  @Component({
    components: {
      Header,
      NotificationsModal
    }
  })
  export default class App extends Vue {
    showMarkup: boolean = false;

    @Watch('$route', { immediate: true })
    onRouteChanged(value: Route) {
      this.showMarkup = value && !!value.name;
    }

  }
</script>

<style lang="scss">
</style>
