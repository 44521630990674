<template>
  <li>
    <b-button v-if="isFolder" class="mb-3 p-0" variant=" " @click="toggle">
      <font-awesome-icon v-if="isOpen" icon="minus-square" />
      <font-awesome-icon v-else icon="plus-square" />
      <b class="ml-2">{{item.displayName}}</b>
    </b-button>
    <TextField v-else :field="item"></TextField>
    <ul v-show="isOpen" v-if="isFolder" class="list-unstyled ml-4 pl-3">
      <TreeViewItem v-for="(child, index) in item.nodes"
                    :key="index"
                    :item="child"></TreeViewItem>
    </ul>
  </li>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { DetailsPanelField } from '@/types/data/detailsPanelField';
  import TextField from './TextField.vue';

  @Component({
    name: 'TreeViewItem',
    components: {
      TextField
    }
  })
  export default class TreeViewItem extends Vue {
    @Prop() item!: DetailsPanelField;
    isOpen: boolean = false;

    get isFolder() {
      return this.item.nodes &&
        this.item.nodes.length;
    }

    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
