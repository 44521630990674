import axios, { AxiosInstance } from 'axios';

// For all operations, have a timeout of 30 seconds.
const defaultTimeout = 30 * 1000;

/**
 * Client for making HTTP requests to the Web API
 */
const apiClient: AxiosInstance = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: '/api/',
  timeout: defaultTimeout
});

export function setRequestAcceptLanguage(locale: string) {
  apiClient.defaults.headers.common['accept-language'] = locale;
}

export function buildFileUrl(
  shareId: string,
  fileId: string,
  isDownload: boolean) {
  let queryString: string = '';
  if (isDownload) {
    queryString = '?instigateFileDownload=true';
  }
  return `/api/file/${shareId}/${fileId}${queryString}`;
}

export default apiClient;
