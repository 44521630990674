<template>
  <b-navbar toggleable="md" type="dark" class="navbarbanner py-1">
    <b-navbar-brand href="#" class="py-0">
      <img class="logo-img" src="/content/images/logo.png" alt="NICE Investigate Logo" width="206" height="40"
           srcset="/content/images/logo.png, /content/images/logo_2x.png 2x, /content/images/logo_3x.png 3x" />
      <span class="logo-caption mb-2 ml-1">
        {{$t('header.logo-caption')}}
      </span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <LanguageSelector></LanguageSelector>
        <Account></Account>
        <Help></Help>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import LanguageSelector from '@/components/nav/LanguageSelector.vue'; // @ is an alias to /src
  import Account from '@/components/nav/Account.vue'; // @ is an alias to /src
  import Help from '@/components/nav/Help.vue'; // @ is an alias to /src

  @Component({
    components: {
      LanguageSelector,
      Account,
      Help
    }
  })
  export default class Header extends Vue { }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .logo-caption {
    font-size: 0.75rem;
  }
  .navbarbanner .navbar-brand {
    display: flex;
    align-items: flex-end;
  }
  .logo-img {
    width: 206px;
    height: 40px;
    min-width: 206px;
    min-height: 40px;
  }
</style>
