import store from '@/store/store';
import { NotificationDetails } from '@/types/notificationDetails';
import languageService from '@/services/languageService';

export class NotificationService {

  async showSessionExpiredModal() {
    const title = languageService.t('notifications.session-expired.title');
    const text = languageService.t('notifications.session-expired.text');

    const notificationDetails: NotificationDetails = {
      title,
      text,
      // reload the page when OK button is clicked
      // (it should take user to CAS then back when authenticated
      // or reload page if already re-authenticated on a different tab)
      okFunc: () => window.location.reload()
    };
    await store.dispatch('notificationStore/show', notificationDetails);
    store.dispatch('notificationStore/clear');
  }
}

const notificationService = new NotificationService();

export default notificationService;
