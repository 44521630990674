<template>
  <div>
    <div class="row mt-5 justify-content-center align-items-center" v-if="isImageLoading">
      <h1><font-awesome-icon icon="spinner" pulse /></h1>
      <h2 class="ml-2">{{$t('evidence.loading')}}</h2>
    </div>
    <div class="row mt-5 justify-content-center align-items-center" v-else-if="hasImageFailed">
      <h2 class="ml-2">{{$t('evidence.media-loading-error')}}</h2>
    </div>
    <div v-show="!isImageLoading && !hasImageFailed" class="text-center">
      <img :src="fileUrl()" @load="imageLoaded" @error="imageLoadError" class="img-fluid">
    </div>

  </div>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { EvidenceFile } from '@/types/data/evidenceFile';
  import { buildFileUrl } from '@/services/apiClient';

  @Component
  export default class ImageViewer extends Vue {
    @Prop() mediaFile!: EvidenceFile;

    isImageLoading: boolean = true;
    hasImageFailed: boolean = false;

    imageLoadError() {
      this.isImageLoading = false;
      this.hasImageFailed = true;
    }
    imageLoaded() {
      this.isImageLoading = false;
    }

    fileUrl() {
      return buildFileUrl(this.$route.params.id, this.mediaFile.fileId, false);
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
