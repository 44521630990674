<template>
  <ul class="list-unstyled">
    <TreeViewItem v-for="(item, index) in field.nodes" :key="`treeview-${index}`"
    class="item"
    :item="item">
      {{item.displayName}}
    </TreeViewItem>
  </ul>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { DetailsPanelField } from '@/types/data/detailsPanelField';
  import TreeViewItem from './TreeViewItem.vue';

  @Component({
    components: {
      TreeViewItem
    }
  })
  export default class JsonTreeField extends Vue {
    @Prop() field!: DetailsPanelField;
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
