import Vue from 'vue';
import Router from 'vue-router';
import Evidence from '@/views/Evidence.vue';
import authService from '@/services/authService';
import languageService from '@/services/languageService';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
      meta: {
        title: 'page-title.default'
      }
    },
    {
      path: '/evidence/:id',
      name: 'evidence',
      component: Evidence,
      meta: {
        title: 'page-title.default'
      }
    },
    {
      path: '*',
      redirect: '/' // client url
    }
  ]
});

// TODO: this has to be reviewed once we have authentication service plugged in
router.beforeEach(async (to, from, next) => {

  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = languageService.t(nearestWithTitle.meta.title);
  } else {
    document.title = languageService.t('page-title.default');
  }

  const requiresAuthentication = to.matched.some(x => x.path === '/evidence/:id');
  try {
    const isAuthenticated = await authService.checkIfAuthenticated();
    const isPathAllowed = requiresAuthentication ? isAuthenticated : true;
    if (isPathAllowed) {
      next();
    } else {
      authService.redirect(to.fullPath);
    }
  } catch (error) {
    console.error(error);
    window.location.href = '/error';
  }
});

export default router;

