/**
 * Module to provide functionality around cookies
 */
export default class CookieService {
  static readonly languageCodeKey = 'sp.language-code';

  /**
   * Gets the language code cookie value
   * @return {string|null} The language code string or null
   */
  static getLanguageCode(): string | null {
    const languageCodeCookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${this.languageCodeKey}=`))
      ?.split('=')[1];

    return languageCodeCookieValue ?? null;
  }

  /**
   * Sets the language code cookie with an expiry of 1 year
   * @param {string} languageCode The language code to use
   */
  static setLanguageCode(languageCode: string): void {
    const yearFromNow = new Date();
    yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);

    document.cookie = `${this.languageCodeKey}=${languageCode}; Expires=${yearFromNow.toUTCString()}; Path=/; Secure; SameSite=Lax;`;
  }
}
