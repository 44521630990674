import { ActionTree, Module, MutationTree } from 'vuex';
import { AuthState } from '@/types/store/authState';
import { RootState } from '@/types/store/rootState';

const state: AuthState = {
  isAuthenticated: null
};

const mutations: MutationTree<AuthState> = {
  setIsAuthenticated(theState: AuthState, isAuthenticatedValue: boolean) {
    theState.isAuthenticated = isAuthenticatedValue;
  }
};

export const actions: ActionTree<AuthState, RootState> = {
  isAuthenticated({ commit, state: theState }, isAuthenticatedValue: boolean): void {
    commit('setIsAuthenticated', isAuthenticatedValue);
  }
};

export const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
