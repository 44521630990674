<template>
  <b-card class="mt-4">
    <div v-if="includeTitle">
      <b-card-title>
        {{$t('evidence.default-viewer-title', {extension: mediaFile.fileExtension})}}
      </b-card-title>
      <b-card-sub-title>
        {{$t('evidence.default-viewer-sub-title')}}
      </b-card-sub-title>
    </div>
    <div v-else>
      <b-card-sub-title>
        {{$t('evidence.further-viewing-options-sub-title', {extension: mediaFile.fileExtension})}}
      </b-card-sub-title>
    </div>

    <b-card-text class="mt-4">
      <ul>
        <li>
          <a :href="openEvidence()" target="_blank">{{$t('evidence.open-evidence-a')}}</a>
          {{$t('evidence.open-evidence-text')}}
        </li>
        <li>
          <a :href="downloadEvidence()">{{$t('evidence.download-evidence-a')}}</a>
          {{$t('evidence.download-evidence-text')}}
        </li>
      </ul>
    </b-card-text>
  </b-card>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { EvidenceFile } from '@/types/data/evidenceFile';
  import { buildFileUrl } from '@/services/apiClient';

  @Component
  export default class DefaultViewer extends Vue {
    @Prop() mediaFile!: EvidenceFile;
    @Prop() includeTitle!: boolean;

    downloadEvidence() {
      return buildFileUrl(this.$route.params.id, this.mediaFile.fileId, true);
    }

    openEvidence() {
      return buildFileUrl(this.$route.params.id, this.mediaFile.fileId, false);
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
