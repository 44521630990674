<template>
  <b-nav-item href="/signout" v-if="isAuthenticated">
    <span slot="button-content">
      <font-awesome-icon icon="sign-out-alt" />
      <span class="ml-1">{{$t('header.logout')}}</span>
    </span>
  </b-nav-item>
</template>

<script lang="ts">
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { AuthState } from '@/types/store/authState';

  @Component
  export default class Account extends Vue {
    isAuthenticated: boolean = false;
    @State('authStore') private authState!: AuthState;

    @Watch('authState.isAuthenticated', { immediate: true })
    private onIsAuthenticatedStateChanged(state: boolean | null) {
      this.isAuthenticated = state === true ? true : false;
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
