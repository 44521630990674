<template>
  <div> <!-- FieldsPanel -->
    <b-row v-if="data && data.length > 0">
      <b-col :cols="bootstrapColumnsCount" v-for="(column, index) in splitIntoColumns()" :key="`column-${index}`">
        <div v-for="(field, irow) in column" :key="`row-${irow}`"> <!-- Field -->
          <TextField v-if="field.type === panelFieldType.TextField" :field="field"></TextField>
          <JsonTreeField v-if="field.type === panelFieldType.JsonField" :field="field"></JsonTreeField>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="3">
        {{$t('evidence.no-information')}}
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DetailsPanelField } from '@/types/data/detailsPanelField';
import TextField from '@/components/evidence/fields/TextField.vue';
import JsonTreeField from '@/components/evidence/fields/JsonTreeField.vue';
import { PanelFieldType } from '@/types/enums/panelFieldType';
import { ArraySplitter } from '@/utils/ArraySplitter';

// Used for displaying both Comments and Bookmarks as Bookmark class inherits from Comment class
@Component({
  components: {
    TextField,
    JsonTreeField
  }
})
export default class FieldPanel extends Vue {
  @Prop() data!: DetailsPanelField[];
  @Prop() columnsNumber!: number;

  panelFieldType = PanelFieldType;
  bootstrapColumnsCount: number = 6;

  private internalColumnsNumber: number = 2;

  created() {
    this.ensureColumnsNumberSet();
    this.bootstrapColumnsCount = Math.floor(12 / this.internalColumnsNumber);
  }

  splitIntoColumns() {
    this.ensureColumnsNumberSet();
    const array = ArraySplitter.split(this.data, this.internalColumnsNumber);
    return array;
  }

  private ensureColumnsNumberSet() {
    if (this.columnsNumber === 0) {
      this.internalColumnsNumber = 2;
    } else {
      this.internalColumnsNumber = this.columnsNumber;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
