import moment from 'moment';

export default abstract class DateTimeHelper {

  static dateTimeDisplayFormat = 'LL, LTS';
  static allowedDateTimeFormats = ['DD/MM/YYYY HH:mm:ss Z', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ', 'M/DD/YYYYh:mm:ssA', 'DD/MM/YYYYHH:mm:ssZ', 'MM/DD/YYYYHH:mm:ss'];

  // Return true if the given string can be parsed as a date
  static isDate(value: string): boolean {
    // Parse string for a date, using strict parsing (only allow exact match on the formats provided)
    const date = moment(value.replace(/ /g, ''), this.allowedDateTimeFormats, true);
    return date.isValid();
  }

  // Return a date in the form 15/02/2019 8:15:59 PM
  static getFormattedDate(value: string): string {
    // Parse string for a date, using strict parsing (only allow exact match on the formats provided)
    const date = moment(value.replace(/ /g, ''), this.allowedDateTimeFormats, true);
    return date.format(this.dateTimeDisplayFormat);
  }
}
