import { Component, Vue } from 'vue-property-decorator';
import notificationService from '@/services/notificationService';
import authService from '@/services/authService';
import { State } from 'vuex-class';
import { AuthState } from '@/types/store/authState';

@Component
export default class BaseView extends Vue {
  timeoutRef: number | null = null;
  @State('authStore') private authState!: AuthState;

  async mounted() {
    const isAuthenticated = this.authState.isAuthenticated;
    // make sure we don't check expiry time when user is not authenticated
    if (isAuthenticated) {
      await this.checkAccessTokenExpiry(new Date());
    }
  }

  destroyed() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  async checkAccessTokenExpiry(date: Date) {

    const secondsUntilMidnight: number = authService.secondsToAccessTokenExpiry(date);

    if (secondsUntilMidnight <= 0) {
      console.info('The access token has expired');
      try {
        const isAuthenticated = await authService.checkIfAuthenticated();
        if (!isAuthenticated) {
          // make sure user is not authenticated
          notificationService.showSessionExpiredModal();
        } else {
          console.warn('User still authenticated');
          // we don't want to show the popup if user is authenticated
          // wait 5 sec, then check again
          this.timeoutRef = setTimeout(() => this.checkAccessTokenExpiry(date), 5 * 1000);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (secondsUntilMidnight < 60) {
      // wait 1 sec, then check again
      this.timeoutRef = setTimeout(() => this.checkAccessTokenExpiry(date), 1 * 1000);
    } else {
      // wait half the time then check again
      this.timeoutRef = setTimeout(() => this.checkAccessTokenExpiry(date), secondsUntilMidnight * 500);
    }
  }
}
