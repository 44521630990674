<template>
  <div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { NotificationState } from '@/types/store/notificationState';
  import { NotificationDetails } from '@/types/notificationDetails';
  import languageService from '@/services/languageService';

  @Component
  export default class NotificationsModal extends Vue {
    @State('notificationStore') private notificationState!: NotificationState;

    @Watch('notificationState.notification')
    private onNotificationStateChanged(notification: NotificationDetails) {
      if (notification) {
        this.openOkModal(notification.title, notification.text, notification.okFunc);
      }
    }

    private openOkModal(title: string, content: string, okFunc?: () => void): Promise<any> {
      const okTitle: string = languageService.t('ok');
      return this.$bvModal.msgBoxOk(content,
        {
          title,
          okTitle,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true

        })
        .then(value => {
          // msgBoxOk always returns value = true
          if (okFunc) {
            okFunc();
          }
          return value;
        })
        .catch(err => {
          throw err;
        });
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
