<template>
  <div>
    <div role="tablist" v-if="data.reportOptions.includeCommentsAndBookmarks">
      <DetailsPanel :title="$t('evidence.bookmarks-title', { 'count': data.evidenceItem.bookmarks.length})" id="accordion-bookmarks" v-if="data.evidenceItem.bookmarks">
        <Comments :data="data.evidenceItem.bookmarks"></Comments>
      </DetailsPanel>
      <DetailsPanel :title="$t('evidence.comments-title', { 'count': data.evidenceItem.comments.length})" id="accordion-comments" v-if="data.evidenceItem.comments">
        <Comments :data="data.evidenceItem.comments"></Comments>
      </DetailsPanel>
    </div>
    <div role="tablist">
      <template v-if="data.evidenceItem.detailsPanels">
        <DetailsPanel v-for="(panel, index) in data.evidenceItem.detailsPanels" :title="panel.title" :key="`panel-${index}`" :id="'accordion-details-'+index">
          <FieldPanel v-if="panel.type === panelType.FieldPanel" :data="panel.fields" :columnsNumber="panel.columnsNumber"></FieldPanel>
          <TablePanel v-else-if="panel.type === panelType.TablePanel" :fields="panel.fields" :values="panel.displayTableValues"></TablePanel>
        </DetailsPanel>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Comments from '@/components/evidence/panels/Comments.vue';
  import { ShareDetails } from '@/types/data/shareDetails';
  import DetailsPanel from '@/components/evidence/panels/DetailsPanel.vue';
  import FieldPanel from '@/components/evidence/panels/FieldPanel.vue';
  import TablePanel from '@/components/evidence/panels/TablePanel.vue';
  import { PanelType } from '@/types/enums/panelType';

  @Component({
    components: {
      Comments,
      DetailsPanel,
      FieldPanel,
      TablePanel
    }
  })
  export default class EvidenceDetails extends Vue {
    @Prop() data!: ShareDetails;
    panelType = PanelType;
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
