import { videojs } from 'vue-video-player';
import { SkipButtonMode } from './skipButtonComponent';
import eventNames from '@/utils/eventNames';

const Plugin = videojs.getPlugin('plugin');
const skipButton = videojs.getComponent('skipButton');

export default class SkipTime extends Plugin {
  constructor(player: any, options: any) {
    super(player, options);

    const buttonsSet: any[] = options.buttonsSet ? options.buttonsSet : [];

    player.ready(() => {
      let backwardButton: any = null;
      let forwardButton: any = null;

      // Add the skip buttons
      let buttonOrder: number = options.buttonOrder ?? 0;
      buttonsSet.forEach(opt => {
        const button = new skipButton(player,
          {
            el: skipButton.prototype.createEl(opt.step, SkipButtonMode.Time),
            step: opt.step
          });

        if (opt.step > 0) {
          forwardButton = button;
        } else {
          backwardButton = button;
        }

        player.controlBar.addChild(button, {}, buttonOrder++);
      });

      player.on(eventNames.mediaPlayerEvents.skipBackward, () => {
        backwardButton?.handleClick();
      });

      player.on(eventNames.mediaPlayerEvents.skipForward, () => {
        forwardButton?.handleClick();
      });
    });
  }
}

videojs.registerPlugin('skipTime', SkipTime);
