<template>
  <b-nav-item-dropdown id="my-nav-dropdown"
                       text="Dropdown"
                       toggle-class="nav-link-custom"
                       right
                       v-if="languagesList.length > 1">
    <template slot="button-content">
      <img :src="'/flags/' + currentLanguage.flagIconFilename" width="20" height="15" alt="" />
      <span class="ml-1 mr-1">{{ currentLanguage.name }}</span>
    </template>
    <b-dropdown-item v-for="language in languagesList" :key="language.code" @click="changeLocale(language.code)">{{ language.name }}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LanguageService from '@/services/languageService';
import { SupportedLanguage } from '@/types/supportedLanguage';

@Component
export default class LanguageSelector extends Vue {
  languagesList: SupportedLanguage[] = LanguageService.availableLanguagesList;
  currentLocale: any = LanguageService.currentLocale;

  get currentLanguage(): SupportedLanguage {
    return this.languagesList.find(x => x.code === this.currentLocale) ?? this.languagesList[0];
  }

  changeLocale(locale: string): void {
    LanguageService.changeLocale(locale);
    this.currentLocale = LanguageService.currentLocale;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
