
export class ArraySplitter {

  // We have to deal with the case when the array does not split into equal sized chunks
  static split(array: any[], numberOfChunks: number): any[] {
    if (!array || array.length === 0) {
      return [];
    }

    const n: number = array.length; // number of entries in the array
    const c: number = numberOfChunks < n ? numberOfChunks : n; // number of chunks
    const r: number = Math.floor(n / c); // number of entries in a chunk
    let numExtraEntries: number = n - r * c; // some chunks may have an extra entry
    // eg split array length 5 into 4 chunks => 2.items, 1.item, 1 item. 1 item ( 1 extra on the first chunk)
    // eg split array length 6 into 4 chunks => 2.items, 2.items, 1 item. 1 entry ( 1 extra on the first 2 chunks)

    const chunks = [];
    let startIndex = 0;
    for (let i = 0; i < numberOfChunks; i++) {
      let chunkSize = r;
      if (numExtraEntries > 0) {
        chunkSize += 1;
        numExtraEntries--;
      }
      const column = array.slice(startIndex, startIndex + chunkSize);
      startIndex += chunkSize;
      chunks.push(column);
    }

    return chunks;
  }

}
