import { LocaleMessageObject } from 'vue-i18n';
import en from './en.json';
import cy from './cy.json';
import PseudoTranslator from './pseudoTranslator';

const translations: Record<string, LocaleMessageObject> = {
  'en-us': en,
  'en-gb': en,
  'cy': cy,
  'vi-vn': PseudoTranslator.createMessages(en) // Use the Vietnamese locale for the dummy / pseudo language
};

export default translations;
