<template>
  <div v-if="data && data.length > 0">
    <div role="tablist" v-for="(item, index) in data" :key="item.id">
      <b-row>
        <b-col md="3">
          <b-card-text class="mb-1"><b>{{ $t('evidence.comment-bookmark-created-by') }}</b>{{ item.userName }}</b-card-text>
          <b-card-text class="mb-1"><b>{{ $t('evidence.at') }}</b>{{ $moment(item.date).format(displayFormat) }}</b-card-text>
          <div v-if="item.lastEditedTimestamp">
            <b-card-text class="mb-1"><b>{{ $t('evidence.comment-bookmark-edited-by', {'userName': item.lastEditedByUserDisplayName}) }}</b></b-card-text>
            <b-card-text class="mb-1"><b>{{ $t('evidence.at') }}</b>{{ $moment(item.lastEditedTimestamp).format(displayFormat) }}</b-card-text>
          </div>
        </b-col>
        <b-col md="9">
          <b-card-text class="mb-1" v-if="item.bookmarkTimeInSeconds">
            <small class="text-muted">{{$t('evidence.time')}}: </small>
            <small><b>{{ item.bookmarkTimeInSeconds | duration }}</b></small>
          </b-card-text>
          <b-card-text>
            <template v-html="item.text"></template>
          </b-card-text>
        </b-col>
      </b-row>
      <hr v-if="index !== data.length - 1" />
    </div>
  </div>
  <div v-else>
    <b-row>
      <b-col md="3">
        {{$t('evidence.no-entries')}}
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Bookmark } from '@/types/data/bookmark';
  import DateTimeHelper from '@/utils/DateTimeHelper';

  // Used for displaying both Comments and Bookmarks as Bookmark class inherits from Comment class
  @Component
  export default class Comments extends Vue {
    @Prop() data!: Bookmark[];
    displayFormat: string = DateTimeHelper.dateTimeDisplayFormat;
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
