<template>
  <div>
    <video-player class="vjs-custom-skin"
                  ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                  @ready="onPlayerReady">
    </video-player>

    <bookmark-carousel ref="bookmarkCarousel"
                       :activeMarkerIndex.sync="activeMarkerIndex"
                       :bookmarks="bookmarks"></bookmark-carousel>

  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EvidenceFile } from '@/types/data/evidenceFile';
import { Bookmark } from '@/types/data/bookmark';

// standard video.js CSS
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';

// vue video.js + plugins
import { videoPlayer } from 'vue-video-player';
import BookmarkCarousel from './BookmarkCarousel.vue';
import '@/plugins/skipTimePlugin';
import '@/plugins/skipFramePlugin';
import '@/plugins/bookmarkPlugin';
import '@/plugins/bookmarkSummaryComponent';
import { buildFileUrl } from '@/services/apiClient';
import MediaPlayerUtils from '@/utils/mediaPlayerUtils';

@Component({
  components: {
    videoPlayer,
    BookmarkCarousel
  }
})
export default class MediaPlayer extends Vue {
  @Prop() mediaFile!: EvidenceFile;
  @Prop() bookmarks!: Bookmark[];
  @Prop() includeCommentsAndBookmarks!: boolean; // prop

  activeMarkerIndex: number = -1;
  playerOptions: any = null;
  buttonOrder: number = 4; // play button + volume + three time buttons

  $refs!: {
    bookmarkCarousel: BookmarkCarousel,
    videoPlayer: any
  };

  created() {
    this.playerOptions = this.getPlayerOptions();
  }

  private contextMenuHandler(e: any) {
    e.preventDefault();
  }

  private onContextMenuHandler() {
    if (window && window.event) {
      window.event.returnValue = false;
    }
  }

  mounted() {
    const vueVideoPlayer = this.$refs.videoPlayer;
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (vueVideoPlayer.player) {
        MediaPlayerUtils.handleMediaHotkeys(event, vueVideoPlayer.player);
      }
    });

    const element = this.$refs.videoPlayer.$el;
    if (element.addEventListener) {
      element.addEventListener('contextmenu', this.contextMenuHandler, false);
    } else {
      element.attachEvent('oncontextmenu', this.onContextMenuHandler);
    }
  }

  beforeDestroy() {
    const element = this.$refs.videoPlayer.$el;
    if (element.removeEventListener) {
      element.removeEventListener('contextmenu', this.contextMenuHandler, false);
    } else {
      element.detachEvent('oncontextmenu', this.onContextMenuHandler);
    }
  }

  getPlayerOptions() {
    const skipTimeButtonsSet = [
      { step: -10 },
      { step: 30 }
    ];

    const options: any = {
      autoplay: false,
      language: 'en', // TODO: use $t
      playbackRates: [0.5, 1.0, 1.5, 2.0],
      inactivityTimeout: 0, // Ensures controls are always visible
      controlBar: {
        fullscreenToggle: true,
        timeDivider: true,
        durationDisplay: true,
        remainingTimeDisplay: false,
        volumePanel: false
      },
      sources: [
        {
          type: this.mediaFile.mimeType,
          src: buildFileUrl(this.$route.params.id, this.mediaFile.fileId, false)
        }
      ],
      plugins: {
        skipTime: {
          buttonsSet: skipTimeButtonsSet,
          buttonOrder: ++this.buttonOrder
        },
        skipFrame: {
          frameRate: this.mediaFile.frameRate,
          buttonOrder: this.buttonOrder + skipTimeButtonsSet.length
        },
        bookmarkPlugin: {
          getBookmarkList: this.getBookmarkList,
          includeCommentsAndBookmarks: this.includeCommentsAndBookmarks,
          emitSetActiveMarkerIndex: this.emitSetActiveMarkerIndex
        }
      }
    };
    return options;
  }

  getBookmarkSummaryCaption() {
    return this.includeCommentsAndBookmarks
      ? this.$t('evidence.bookmarks-title', { count: this.bookmarks.length })
      : '';
  }
  getBookmarkList() {
    return this.bookmarks;
  }

  showBookmarkCarousel() {
    return this.$refs.bookmarkCarousel.showBookmarkCarousel();
  }

  emitSetActiveMarkerIndex(index: number) {
    this.$refs.bookmarkCarousel.setSlideIndex(index);
  }

  onPlayerReady(vuePlayer: any) {
    const player = vuePlayer.player();

    // Add bookmark summary and volumePanel in determined order:
    // after skip buttons so that tab works as expected in visual order
    player.controlBar.addChild('bookmarkSummary', {
      getBookmarkSummaryCaption: this.getBookmarkSummaryCaption,
      includeCommentsAndBookmarks: this.includeCommentsAndBookmarks,
      showBookmarkCarousel: this.showBookmarkCarousel,
      bookmarksCount: this.bookmarks.length
    }, ++this.buttonOrder);
    player.controlBar.addChild('volumePanel', { inline: false }, ++this.buttonOrder);

    player.firstPlayDone = false;
    player.one('play', () => {
      player.firstPlayDone = true;
    });

    const playbackRateButtonContainer = player.controlBar.playbackRateMenuButton;
    if (playbackRateButtonContainer) {
      // make screen readers announce updated playback speed
      playbackRateButtonContainer.el().ariaLive = 'polite';
      const playbackRateButton = playbackRateButtonContainer.getChild('PlaybackRateMenuButton').el();
      if (playbackRateButton) {
        // remove attributes that cause extra info to be announced
        playbackRateButton.ariaHasPopup = false;
        playbackRateButton.ariaExpanded = null;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .video-js {
    min-height: 250px;
    max-height: calc(100vh * 0.9 - 180px);

    &:fullscreen {
      max-height: 100%;
    }
  }

  .vjs-custom-skin > .video-js .vjs-progress-control.vjs-control {
    order: 2;
    position: absolute;
    bottom: 3em;
    left: 0;
    height: 10px;
    width: 100%;
  }

  .video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0;
  }

  .video-js .vjs-progress-holder {
    flex: auto;
    transition: all 0.2s;
    height: 10px;
  }

  .vjs-custom-skin > .video-js .vjs-play-progress:before {
    top: -0.2em;
  }

  .video-js .vjs-play-progress:before {
    font-size: 18px;
    position: absolute;
    right: -0.5em;
    top: -0.2em;
    z-index: 1;
  }

  .vjs-bookmark-summary {
    color: white;
    order: 3;
    display: flex;
  }

  .vjs-button-bookmark-summary {
    padding: 0 .9em;
  }

  .vjs-bookmark-summary-text {
    font-size: 1.1em;
    line-height: 0.9em;
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: normal;
  }

  @media screen and (min-width: 750px) {
    .video-js .vjs-bookmark-summary {
      margin-left: auto;
    }
  }

  .vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate {
    order: 5;
  }

  .vjs-marker {
    height: 10px;
    position: absolute;
    z-index: 1;
    width: 7px;
    background-color: white;
    border-radius: 2px;
  }

  .vjs-marker.active {
    width: 9px; /* Make the active marker 2px wider */
    margin-left: -1px; /* Widen 1px from the left and right  */
    background-color: yellow;
    border-radius: 0;
  }

  .vjs-custom-skin > .video-js .vjs-time-tooltip {
    top: -1.8em; /* Move the tooltops down closer to the progres bar from the default of -3em */
  }
  /* Time skipping video-js plugin */
  .vjs-skip-button.vjs-control,
  .vjs-step-button.vjs-control {
    padding-top: 1px;

    &:before {
      display: block;
      width: 50%;
      height: 50%;
      margin: 0 auto;
      content: '';
      background-repeat: no-repeat;
      background-position-x: center;
    }

    &.vjs-skip-forwards-button {
      order: 1;

      &:before {
        background-image: url('/img/media-player/skip-forwards.svg');
      }
    }

    &.vjs-skip-backwards-button {
      order: 1;

      &:before {
        background-image: url('/img/media-player/skip-backwards.svg');
      }
    }

    &.vjs-step-forwards-button {
      order: 2;

      &:before {
        background-image: url('/img/media-player/step-forwards.svg');
      }
    }

    &.vjs-step-backwards-button {
      order: 2;

      &:before {
        background-image: url('/img/media-player/step-backwards.svg');
      }
    }
  }

  .vjs-step-button.vjs-control {
    display: none;
  }

  .vjs-has-started.vjs-paused {
    .vjs-step-button.vjs-control {
      display: block;
    }

    .vjs-big-play-button {
      display: none;
    }
  }

  .vjs-custom-skin > .video-js .vjs-control-bar .vjs-fullscreen-control {
    order: 6;
  }

  .vjs-custom-skin > .video-js .vjs-control-bar .vjs-volume-panel {
    order: 4;
  }

  /*
    Bugfix 148140: Make sure video isn't occluded by controls, if controls are set to fade out this will also
    gracefully grow/shrink the video panel to maximise visible video area without occlusion.
  */
  .vjs-tech {
    transition: height 0.1s ease-in-out
  }

  div.video-player.vjs-controls-disabled > .vjs-tech, .vjs-user-inactive .vjs-tech {
    height: 100%;
  }

  .vjs-ended.vjs-user-inactive .vjs-tech, .vjs-user-active .vjs-tech {
    height: calc(100% - 52px); /* Remove height of control and progress bars */
  }
</style>
