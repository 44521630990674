import keyCodes from '@/utils/keyCodes';
import eventNames from '@/utils/eventNames';

class MediaPlayerUtils {
  static handleMediaHotkeys(event: KeyboardEvent, player: any) {
    switch (event.keyCode) {
      case keyCodes.mediaPlayPause: {
        if (player.firstPlayDone) {
          // webbrowser may handles media play/pause button itself
          // JS engine can't be aware about that so let's check later
          if (player.paused()) {
            this.runWithTimeout(this.forcePlayerToPlay, player);
          } else {
            this.runWithTimeout(this.forcePlayerToPause, player);
          }
        } else {
          // webbrowser doesn't handle play/pause before initial play
          // but because of handling key events with some delay it's possible situation:
          // 1. user press media key
          // 2. webbrowser handles that key and applies changes afterwards
          // 3. code forces player to play
          // 4. webbrowser back with handled key and notice that player is playing and it's needed to pause it
          // to cover such situation there is a need to start initial play with some delay
          this.runWithTimeout(this.startInitialPlay, player);
        }
        break;
      }
      case keyCodes.mediaStop: {
        if (!player.firstPlayDone) {
          return;
        }
        this.runWithTimeout(this.forcePlayerToStop, player);
        break;
      }
      case keyCodes.nextTrack: {
        if (!player.firstPlayDone) {
          return;
        }
        player.trigger(eventNames.mediaPlayerEvents.skipForward);
        break;
      }
      case keyCodes.prevTrack: {
        if (!player.firstPlayDone) {
          return;
        }
        player.trigger(eventNames.mediaPlayerEvents.skipBackward);
        break;
      }
    }
  }

  private static forcePlayerToPlay(player: any) {
    if (player.paused()) {
      player.play();
    }
  }

  private static forcePlayerToPause(player: any) {
    if (!player.paused()) {
      player.pause();
    }
  }

  private static forcePlayerToStop(player: any) {
    if (!player.paused()) {
      player.pause();
    }
    if (player.currentTime() !== 0) {
      player.currentTime(0);
    }
  }

  private static startInitialPlay(player: any) {
    if (player.bigPlayButton) {
      player.bigPlayButton.el().click();
    } else {
      player.play();
    }
  }

  private static runWithTimeout(methodToBeCalled: (...params: any) => void, ...params: any) {
    setTimeout(() => methodToBeCalled(...params), 200);
  }
}

export default MediaPlayerUtils;
