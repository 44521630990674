import { LocaleMessage, LocaleMessageObject } from 'vue-i18n';

// TODO: Review if this should be a node script run via npm to generate the vi-vn.json file

export default class PseudoTranslator {

  static createMessages(englishMessages: LocaleMessageObject): LocaleMessageObject {

    const iterateProperties = (messages: LocaleMessage) => {
      Object.keys(messages).forEach((key: string) => {

        // @ts-expect-error: TS7053: Element implicitly has an any type because expression of type string can't be used to index type LocaleMessage
        const currentProperty: LocaleMessage = messages[key];
        if (typeof currentProperty === 'object') {
          iterateProperties(currentProperty);
        } else {
          // @ts-expect-error: TS2345: Argument of type string | MessageFunction is not assignable to parameter of type string
          messages[key] = PseudoTranslator.getPseudoTranslation(currentProperty);
        }
      });
    };

    const copyOfEnglish: LocaleMessageObject = JSON.parse(JSON.stringify(englishMessages));
    iterateProperties(copyOfEnglish);
    return copyOfEnglish;
  }

  static getPseudoTranslation(text: string): string {
    const split = text.split(PseudoTranslator.paramsSplitRegExp).filter(Boolean);
    let output = '';
    split.forEach(subStr => {
      if (subStr.startsWith('{') && subStr.endsWith('}')) {
        output += subStr;
      } else {
        // @ts-expect-error: TS7053: Element implicitly has an any type because expression of type string can't be used to index type
        output += subStr.replace(PseudoTranslator.regExp, char => PseudoTranslator.characterReplacements[char]);
      }
    });
    return output + ' !!!';
  }

  private static characterReplacements = {
    a: 'ä', // Lowercase
    b: 'ẞ',
    e: 'ë',
    i: 'ï',
    n: 'ñ',
    o: 'ö',
    u: 'ü',

    A: 'Ä', // Uppercase
    B: 'ẞ',
    E: 'Ë',
    I: 'Ï',
    N: 'Ñ',
    O: 'Ö',
    U: 'Ü'
  };

  private static charactersToMatch = Object.keys(PseudoTranslator.characterReplacements).join('');
  private static regExp: RegExp = new RegExp(`[${PseudoTranslator.charactersToMatch}]`, 'g');
  private static paramsSplitRegExp: RegExp = new RegExp('({.*})', 'g');
}
