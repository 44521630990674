import videojs_ from 'video.js';
import { videojs } from 'vue-video-player';

const Button = videojs.getComponent('Button');

export default class SkipButton extends Button {
  step: number;
  localPlayer: videojs_.Player;

  constructor(player: videojs_.Player, options: any) {
    super(player, options);
    this.localPlayer = player;
    this.step = options.step ? options.step : 5; // default 5
  }

  createEl(step: number, mode: SkipButtonMode): any {
    const direction = this.getDirection(step);
    const modeName = this.getMode(mode);
    const stepWording = this.getStepWording(step, mode);
    const buttonComponent = videojs.dom.createEl('button', {
      className: `vjs-${modeName}-button vjs-${modeName}-${direction}-button vjs-control vjs-button`,
      innerHTML: `<span class="vjs-control-text" aria-live="polite">Skip ${stepWording} ${direction}</span>`
    }, {
      role: 'button',
      title: `Skip ${stepWording} ${direction}`
    });

    return buttonComponent;
  }

  handleClick() {
    videojs.log(`Time skipped ${this.getDirection(this.step)} by ${Math.abs(this.step)}s`);
    this.localPlayer.currentTime(this.localPlayer.currentTime() + this.step);
  }

  getDirection(step: number): string {
    if (step && step < 0) {
      return 'backwards';
    }
    return 'forwards';
  }

  getMode(mode: SkipButtonMode): string {
    if (mode && mode === SkipButtonMode.Frame) {
      return 'step';
    }
    return 'skip';
  }

  getStepWording(step: number, mode: SkipButtonMode): string {
    if (mode && mode === SkipButtonMode.Frame) {
      return '1 frame';
    }
    return `${Math.abs(step)}s`;
  }
}

export enum SkipButtonMode {
  Time = 0,
  Frame = 1
}

videojs.registerComponent('skipButton', SkipButton);
