import videojs_ from 'video.js';
import { videojs } from 'vue-video-player';
import { Bookmark } from '@/types/data/bookmark';
import { MediaPlayerMarker } from '@/types/mediaPlayerMarker';

const Plugin = videojs.getPlugin('plugin');

export default class BookmarkPlugin extends Plugin {
  getBookmarkList: () => Bookmark[];
  emitSetActiveMarkerIndex: (index: number) => void;
  markersList: MediaPlayerMarker[];


  constructor(player: any, options: any) {
    super(player, options);

    if (options.customClass) {
      player.addClass(options.customClass);
    }
    this.getBookmarkList = options.getBookmarkList;
    this.emitSetActiveMarkerIndex = options.emitSetActiveMarkerIndex;
    this.markersList = [];

    player.on('loadedmetadata',
      () => {
        player.bookmarkPlugin().addMarkersToProgressBar(player);
      });
  }

  addMarkersToProgressBar(player: videojs_.Player) {
    this.removeAllMarkers();
    const progressHolder = videojs.dom.$('.vjs-progress-holder', player);
    this.markersList = this.getMarkersList();
    this.markersList.forEach((marker: MediaPlayerMarker) => {
      const markerDiv = this.createMarkerDiv(marker, player);
      videojs.dom.appendContent(progressHolder, markerDiv);
    },
    this);
  }

  setActiveMarkerIndex(index: number) {
    const marker = this.markersList[index];

    const els = document.querySelectorAll('.vjs-marker');
    if (els && els.length > 0) {
      Array.prototype.forEach.call(els, (el: Element) => {
        videojs.dom.removeClass(el, 'active');
        if (!marker || !marker.key) {
          return;
        }
        const elKey = videojs.dom.getAttribute(el, 'data-marker-key');
        if (elKey === marker.key) {
          videojs.dom.addClass(el, 'active');
        }
      });
    }
  }

  removeAllMarkers() {
    const els = document.querySelectorAll('.vjs-marker');
    if (!els || els.length === 0) {
      return;
    }

    els.forEach(elsItem => {
      if (elsItem.parentNode) {
        const test = elsItem.parentNode as Node;
        test.removeChild(elsItem);
      }
    });
  }

  getMarkersList() {
    return this.getBookmarkList().map((bookmark: Bookmark, idx: number) => {
      return {
        time: bookmark.bookmarkTimeInSeconds,
        key: bookmark.id,
        index: idx
      };
    });
  }

  createMarkerDiv(marker: MediaPlayerMarker, player: videojs_.Player) {
    const markerDiv: HTMLDivElement = videojs.dom.createEl('div',
      {},
      {
        'data-marker-key': marker.key
      });

    this.setMarkderDivStyle(marker, markerDiv, player);
    const callback = this.emitSetActiveMarkerIndex;
    markerDiv.addEventListener('click', () => {
      player.pause();
      const els = document.querySelectorAll('.vjs-marker');

      if (els && els.length > 0) {
        Array.prototype.forEach.call(els, (el: Element) => {
          videojs.dom.removeClass(el, 'active');
        });
      }
      videojs.dom.addClass(markerDiv, 'active');
      callback(marker.index);
    });

    return markerDiv;
  }

  setMarkderDivStyle(marker: MediaPlayerMarker, markerDiv: HTMLDivElement, player: videojs_.Player) {
    markerDiv.className = 'vjs-marker';
    markerDiv.style.left = this.getPosition(marker, player) + '%';
  }

  getPosition(marker: MediaPlayerMarker, player: videojs_.Player) {
    return marker.time / player.duration() * 100;
  }

}

videojs.registerPlugin('bookmarkPlugin', BookmarkPlugin);

