import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/types/store/rootState';

import { authStore } from './modules/authStore';
import { notificationStore } from './modules/notificationStore';

Vue.use(Vuex);


const store: StoreOptions<RootState> = {
  modules: { // The Vuex store is split across different modules
    authStore,
    notificationStore
  }
};

export default new Vuex.Store<RootState>(store);
