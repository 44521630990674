<template>
  <div class="mb-4 mt-4">
    <!-- Show the media player if it is a transcoded media file or a master copy
      (in this latter case it may or may not play) -->
    <MediaPlayer v-if="isPlayableVideoOrAudio() || isPossiblyPlayableVideoOrAudio()"
                 :media-file="primaryMediaFile"
                 :bookmarks="data.bookmarks"
                 :include-comments-and-bookmarks="includeCommentsAndBookmarks"></MediaPlayer>
    <DocumentViewer v-else-if="isPdfDocument()"
                    :media-file="primaryMediaFile"></DocumentViewer>
    <ImageViewer v-else-if="isImage()"
                 :media-file="primaryMediaFile"></ImageViewer>
    <!-- Media played may not match the original warning -->
    <div v-if="showMediaTypeDisclaimer && primaryMediaIsConverted() === true" class="media-disclaimer mt-2">
      <span>
        <font-awesome-icon icon="exclamation-circle" class="media-disclaimer-icon-color" />
        {{ $t('evidence.media-disclaimer-working-copy') }}
      </span>
    </div>
    <!-- Video/Audio may or may not play in the media player warning -->
    <div v-if="isPossiblyPlayableVideoOrAudio()" class="media-disclaimer mt-2">
      <span>
        <font-awesome-icon icon="exclamation-circle" class="media-disclaimer-icon-color" />
        {{ $t('evidence.file-may-not-play-correctly') }}
      </span>
      <br />
    </div>
    <!-- Shows Further/Other options for viewing/playing the media -->
    <DefaultViewer v-if="canDisplayDefaultViewer()"
                   :media-file="primaryMediaFile"
                   :includeTitle="!this.hasSpecificViewer()"
                   @getFileError="handleGetFileError"></DefaultViewer>


  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MediaPlayer from '@/components/evidence/media/MediaPlayer.vue';
import DocumentViewer from '@/components/evidence/media/DocumentViewer.vue';
import ImageViewer from '@/components/evidence/media/ImageViewer.vue';
import DefaultViewer from '@/components/evidence/media/DefaultViewer.vue';
import { EvidenceDetails } from '@/types/data/evidenceDetails';
import { EvidenceFile } from '@/types/data/evidenceFile';

@Component({
    components: {
      MediaPlayer,
      DocumentViewer,
      ImageViewer,
      DefaultViewer
    }
  })
export default class EvidenceMedia extends Vue {
    @Prop() data!: EvidenceDetails;
    @Prop() includeCommentsAndBookmarks!: boolean;
    @Prop() showMediaTypeDisclaimer!: boolean;

    primaryMediaFile: EvidenceFile | null = null;

    created() {
      this.primaryMediaFile = this.data.evidenceFiles.length > 0 ? this.data.evidenceFiles[0] : null;
    }

    primaryMediaFileExists() {
      return this.primaryMediaFile !== null;
    }

    primaryMediaIsConverted() {
      return this.primaryMediaFile?.variantName === 'WorkingCopy';
    }

    isImage(): boolean {
      const result: boolean = this.data.mediaType === 'Image' && this.primaryMediaFileExists();
      return result;
    }

    isPdfDocument(): boolean {
      const result: boolean =
        this.data.mediaType === 'Document' &&
          this.primaryMediaFile?.fileExtension.toLowerCase() === 'pdf';
      return result;
    }

    // These media types (audio, video, image & pdf documents) have a specific viewer
    hasSpecificViewer(): boolean {
      const result: boolean = this.primaryMediaFileExists() &&
        (this.isVideoOrAudioMedia() || this.isImage() || this.isPdfDocument());
      return result;
    }

    isPlayableVideoOrAudio(): boolean {
      const playable: boolean =
        this.primaryMediaFile?.variantName === 'WorkingCopy' ||
         this.primaryMediaFile?.variantName === 'PlayableOriginal';
      return playable && this.isVideoOrAudioMedia();
    }

    isVideoOrAudioMedia(): boolean {
      const mediaIsVideoOrAudio: boolean = this.data.mediaType === 'Video' || this.data.mediaType === 'Audio';
      return mediaIsVideoOrAudio;
    }

    // Video or Audio that has not been transcoded, it may or may not play in the media player
    isPossiblyPlayableVideoOrAudio(): boolean {
      const possiblyPlayable: boolean = this.primaryMediaFile?.variantName === 'Master';
      return possiblyPlayable && this.isVideoOrAudioMedia();
  }

    // When a media file exists AND
    // (it is an audio or video media file but it is unsure if it will play correctly in the media player) OR
    // (it does not have any other specific viewer)
    canDisplayDefaultViewer(): boolean {
      const result: boolean = this.primaryMediaFileExists() &&
        (this.isPossiblyPlayableVideoOrAudio() || !this.hasSpecificViewer());
      return result;
    }

    handleGetFileError() {
      this.$emit('getFileError');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .media-disclaimer {
    text-align: center;

    .media-disclaimer-icon-color {
      color: #007bff;
    }
  }
</style>
