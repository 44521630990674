<template>
  <b-card no-body class="mb-4">
    <b-card-header class="p-0" header-tag="header" role="tab">
      <b-button block href="#" v-b-toggle="id" variant=" " class="text-left">
        <b class="m-0">{{title}}</b>
        <span class="float-right">
          <font-awesome-icon icon="chevron-circle-left" :class="['rotate', 'mt-1', 'mr-1', {'down':showCollapse}]" key="1" />
        </span>
      </b-button>
    </b-card-header>
    <b-collapse :id="id" visible role="tabpanel" v-model="showCollapse">
      <b-card-body class="p-2">
        <div class="pl-1">
          <slot></slot>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class DetailsPanel extends Vue {
    @Prop() title!: string;
    @Prop() id!: string;
    showCollapse: boolean = true;
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .rotate {
    transition: all .35s ease;
  }

    .rotate.down {
      transform: rotate(-90deg);
    }
</style>
