import apiClient from '@/services/apiClient';
import store from '@/store/store';
import moment from 'moment';

export class AuthService {

  async checkIfAuthenticated(): Promise<boolean> {
    let isAuthenticated = false;

    try {
      const authCheck = await apiClient.get('authcheck');
      isAuthenticated = authCheck && authCheck.data ? true : false;
    } catch (err: any) {
      isAuthenticated = false;
      if (!err.response || err.response.status !== 401) {
        console.error(err);
        return Promise.reject(err.response ? err.response.status : 500);
      }
    } finally {
      store.dispatch('authStore/isAuthenticated', isAuthenticated);
    }
    return Promise.resolve(isAuthenticated);
  }

  redirect(returnUrl: string): void {
    const offset = new Date().getTimezoneOffset();
    window.location.href = `/redirect?returnUrl=${returnUrl}&offset=${offset}`;
  }

  secondsToAccessTokenExpiry(date: Date): number {
    const midnight = moment(date).endOf('day');
    const secondsUntilMidnight: number = midnight.diff(moment(new Date()), 'seconds');
    return secondsUntilMidnight;
  }

}

const authService = new AuthService();

export default authService;
