<template>
  <dl class="row">
    <dt class="col-sm-12 col-md-4">{{field.displayName}}: </dt>
    <dd class="col-sm-12 col-md-8">
      <h4 v-if="isArrayValue">
        <b-badge v-for="(word, index) in displayValue" :key="`word-${index}`" class="mr-2 font-weight-normal" variant="secondary">{{word}}</b-badge>
      </h4>
      <span v-else>{{displayValue}}</span>
    </dd>
  </dl>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DetailsPanelField } from '@/types/data/detailsPanelField';
import moment from 'moment';
import DateTimeHelper from '@/utils/DateTimeHelper';

@Component
export default class TextField extends Vue {
  @Prop() field!: DetailsPanelField;
  allowedDateTimeFormats = ['DD/MM/YYYY HH:mm:ss Z', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ', 'M/DD/YYYYh:mm:ssA', 'DD/MM/YYYYHH:mm:ssZ', 'MM/DD/YYYYHH:mm:ss'];
  displayValue: string | string[] = '';
  isArrayValue: boolean = false;

  // There is no type information associated with TextFields, they are all strings. In order to display the field
  // appropriately this method detects the type of data by parsing the string and converting it to an appropriate
  // display format. The detection methods used are identical to a similar mechanism in the Investigate Portal.
  created() {

    this.displayValue = this.field.displayValue;
    if (this.displayValue) {
      try {
        if (this.isDisplayValueNumeric()) {
          // Numbers, with no other formatting, are displayed unchanged
          return;
        }
        if (DateTimeHelper.isDate(this.displayValue)) {
            this.displayValue = DateTimeHelper.getFormattedDate(this.displayValue);
          } else if (this.isDuration(this.displayValue)) {
            this.displayValue = this.getDuration(this.displayValue);
          } else if (this.isLocation(this.displayValue)) {
            this.displayValue = this.getLocation(this.displayValue);
          } else if (this.isArray(this.displayValue)) {
            this.displayValue = this.getArray(this.displayValue);
            this.isArrayValue = true;
          }
        } catch (err) {
          console.error(err);
        }
      }
  }

  // Return true if the given string can be parsed as a duration
  private isDuration(value: string): boolean {
    return !!moment.duration(value).asMilliseconds();
  }

  // Return true if the given string can be parsed as a WKT POINT location
  private isLocation(value: string): boolean {
    // Valid match returned by regex includes two digit strings and one POINT(lon lat) string, i.e. three strings
    const validMatchLength = 3;
    // Match WKT POINT
    const wktPointRegEx
      = /^\s*(?:SRID\s*=\s*\d+\s*;\s*)?POINT\s*\(\s*(-?\d+(?:\.\d+)?)\s+(-?\d+(?:\.\d+)?)\s*\)\s*$/i;

    const matchesWktPointRegEx = value.match(wktPointRegEx);
    return !!matchesWktPointRegEx && matchesWktPointRegEx.length === validMatchLength;
  }

  // Return true if the given string has the appearance of an array
  private isArray(value: string): boolean {
    return value.startsWith('[') && value.endsWith(']');
  }

  // Return a duration value in the form hh:mm:ss.SS, with leading 0 on hours
  private getDuration(value: string): string {
    const durationFormat = 'mm:ss.SS';
    const duration = moment.duration(value);
    let formattedDateTime =
      moment.utc(duration.asMilliseconds()).format(durationFormat);
    const totalHours = Math.trunc(duration.asHours());

    formattedDateTime =
      (totalHours < 10 ? '0' + totalHours : totalHours) + ':' + formattedDateTime;
    return formattedDateTime;
  }

  // Return a location value in "{latitude}, {longitude}" format working for 2 or 3 numbers (e.g. SRID)
  private getLocation(value: string): string {
    // Match numbers with decimal places and numbers that can be negative in a greedy manner!
    const extractNumbersRegEx = /-?\d+(\.\d+)?/g;
    const matchesWktPointRegEx = value.match(extractNumbersRegEx);
    if (!matchesWktPointRegEx ||
      matchesWktPointRegEx.length !== 2 && matchesWktPointRegEx.length !== 3) {
      throw new Error('Unable to match location in validated string ' + value);
    }
    const latitude = matchesWktPointRegEx[matchesWktPointRegEx.length - 1];
    const longitude = matchesWktPointRegEx[matchesWktPointRegEx.length - 2];
    return latitude + ', ' + longitude;
  }

  // Validates the given array by parsing it.
  private getArray(value: string): string {
    return JSON.parse(value);
  }

  private isDisplayValueNumeric(): boolean {
    return !isNaN(Number(this.displayValue));
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
