import { videojs } from 'vue-video-player';
import { SkipButtonMode } from './skipButtonComponent';

const Plugin = videojs.getPlugin('plugin');
const skipButton = videojs.getComponent('skipButton');

export default class SkipFrame extends Plugin {
  constructor(player: any, options: any) {
    super(player, options);

    const frameRate: number = options.frameRate;

    if (!frameRate) {
      return;
    }

    const singleFrameTime = 1 / frameRate;

    const buttonsSet: any[] = [ -singleFrameTime, singleFrameTime ];

    player.ready(() => {
      // Add the skip buttons
      let buttonOrder: number = options.buttonOrder ?? 0;
      buttonsSet.forEach(opt => {
        player.controlBar.addChild(
          new skipButton(player,
            {
              el: skipButton.prototype.createEl(opt, SkipButtonMode.Frame),
              step: opt
            }), {}, buttonOrder++
        );
      });
    });
  }
}

videojs.registerPlugin('skipFrame', SkipFrame);
