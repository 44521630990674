<template>
  <div>
    <div class="row mt-5 justify-content-center align-items-center" v-if="hasLoadingFailed">
      <h2 class="ml-2">{{$t('evidence.media-loading-error')}}</h2>
    </div>
    <div v-else :class="['viewer-container', {'fullscreen':isFullscreen}]">
      <VuePdfjsViewer
        pdfjs-location="/pdfjs/"
        :document-location="src()"
        :full-screen-mode-enabled="true"
        :errorHandlerFunc="mediaLoadingErrorHandler"
        @fullScreenModeChanged="fullScreenModeChangedHandler"
        :zoom="zoom"
        :pageMode="pageMode"
      ></VuePdfjsViewer>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EvidenceFile } from '@/types/data/evidenceFile';
import { VuePdfjsViewer, VuePdfjsViewerZoom, VuePdfjsViewerPageMode } from '@publicsafety/vue-pdfjs';
import { buildFileUrl } from '@/services/apiClient';

@Component({
    components: {
      VuePdfjsViewer
    }
  })
export default class DocumentViewer extends Vue {
    @Prop() mediaFile!: EvidenceFile;
    isFullscreen = false;
    hasLoadingFailed = false;

    private zoom: VuePdfjsViewerZoom = VuePdfjsViewerZoom.PageFit;
    private pageMode: VuePdfjsViewerPageMode = VuePdfjsViewerPageMode.Thumbs;

    src(): string {
      const src: string = buildFileUrl(this.$route.params.id, this.mediaFile.fileId, true);
      return src;
    }

    fullScreenModeChangedHandler(isFullscreen: boolean) {
      if (isFullscreen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
      this.isFullscreen = isFullscreen;
    }

    mediaLoadingErrorHandler(message: string, moreInfo: any) {
      console.error(message, moreInfo);
      this.hasLoadingFailed = true;
    }
}
</script>

<style lang="scss">
.viewer-container {
  display: flex;
  height: calc(100vh * 0.9 - 180px);
  flex-direction: column;
}
.fullscreen {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}
</style>
