<template>

  <div id="bookmark-carousel" class="container-fluid carousel-main-container">

    <div v-show="showCarousel" class="carousel-container mx-auto">

      <div class="carousel-close">
        <button @click="closeCarousel" type="button" :aria-label="$t('close')" class="close">×</button>
      </div>

      <b-carousel controls
                  background="#FFF"
                  img-width="640"
                  img-height="155"
                  v-model="internalSlideIndex"
                  :interval="0">

        <b-carousel-slide img-blank v-for="(bookmark, index) in bookmarks" :key="bookmark.id" background="rgba(255, 255, 255, 0)">
          <div class="heading">{{ $t('evidence.bookmark-header', {'index': index + 1, 'bookmarkTimeInSeconds': convertToDuration(bookmark.bookmarkTimeInSeconds) }) }}</div>
          <div class="summary">
            {{ $t('evidence.bookmark-added', {'userName': bookmark.userName, 'dateTime': $moment(bookmark.date).format(displayFormat) }) }}
            <div class="last-edited-info" v-if="bookmark.lastEditedTimestamp">
              {{ $t('evidence.comment-bookmark-last-edited-by', {'userName': bookmark.lastEditedByUserDisplayName, 'dateTime': $moment(bookmark.lastEditedTimestamp).format(displayFormat)} ) }}
            </div>
          </div>
          <div class="text" v-html="bookmark.text"></div>
        </b-carousel-slide>

      </b-carousel>

    </div>

  </div>

</template>
<script lang="ts">
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Bookmark } from '@/types/data/bookmark';
  import DateTimeHelper from '@/utils/DateTimeHelper';

  // Used for displaying both Comments and Bookmarks as Bookmark class inherits from Comment class
  @Component
  export default class BookmarkCarousel extends Vue {
    @Prop() bookmarks!: Bookmark[];
    displayFormat: string = DateTimeHelper.dateTimeDisplayFormat;

    internalSlideIndex: number = 0;
    showCarousel: boolean = false;

    @Watch('showCarousel', { immediate: true })
    onShowCarouselChanged(value: boolean) {
      if (value) {
        this.$emit('update:activeMarkerIndex', this.internalSlideIndex);
      } else {
        this.$emit('update:activeMarkerIndex', -1);
      }
    }

    @Watch('internalSlideIndex', { immediate: true })
    onInternalSlideIndexChanged(newSlideIndex: number) {
      if (this.showCarousel) {
        this.$emit('update:activeMarkerIndex', newSlideIndex);
      } else {
        this.$emit('update:activeMarkerIndex', -1);
      }
    }

    showBookmarkCarousel(): void {
      if (!this.bookmarks.length) {
        return;
      }
      this.internalSlideIndex = 0;
      this.toggleCarousel();
    }

    setSlideIndex(index: number): void {
      if (this.internalSlideIndex !== index) {
        this.internalSlideIndex = index;
      }
      if (!this.showCarousel) {
        this.showCarousel = true;
      }
    }

    toggleCarousel(): void {
      this.showCarousel = !this.showCarousel;
    }

    closeCarousel(): void {
      this.showCarousel = false;
    }

    convertToDuration(timeInSecounds: number): string {
      try {
        return Vue.filter('duration')(timeInSecounds);
      } catch (err) {
        console.error('An error occurred whilst converting time in seconds into duration: ' + err + '. Default value will be used: ' + timeInSecounds);
        return timeInSecounds.toString();
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  /* Carousel classese that are NOT overrides of the standard Video.js classes. */
  /* These are our own class names for this component */

  .carousel-main-container {
    position: relative;
  }

  .carousel-container {
    position: absolute;
    bottom: 68px;
    z-index: 1;
    margin-bottom: 5px;
    left: 0;
    right: 0;
    width: 440px;
  }

  .carousel-close {
    position: absolute;
    top: 5px;
    right: 21px;
    z-index: 2;
  }

  .carousel-close .close {
    font-size: 1.8em /* Make the icon bigger */
  }

  .carousel .heading {
    font-size: 1em;
    font-weight: bold;
  }

  .carousel .summary {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.9em;
  }

  .carousel .last-edited-info {
    font-weight: normal;
    font-style: italic;
  }

  .carousel .text {
    max-height: 68px;
    overflow: auto;
    font-size: 0.9em;
  }

  @media screen and (min-width: 768px) {
    .carousel-container {
      width: 640px;
    }

    .carousel .heading {
      font-size: 1.125em;
    }

    .carousel .summary {
      margin-bottom: 10px;
      font-size: 1em;
    }

    .carousel .text {
      font-size: 1em;
    }
  }

  /* Carousel classes that MUST ALL BE OVERRIDES of the standard Video.js classes. No new class names to be introduced here. */

  /* The specificity of including .carousel in the selector is required */
  /* With bootstrap-vue, the carousel-caption is the element that contains all the text content */
  .carousel .carousel-caption {
    top: 0; /* Position at the top of the carousel rather than the bottom */
    left: 8%; /* Reduce from 15% */
    right: 8%;
    text-align: left;
    padding: 12px 0; /* Tighten the top and bottom margin from 20px */
    color: #212529;
  }

  .carousel .carousel-control-prev,
  .carousel .carousel-control-next {
    width: 8% /* Reduce from 15% */
  }

  /* Override the svg image size and colour */
  .carousel .carousel-control-prev-icon,
  .carousel .carousel-control-next-icon {
    width: 18px;
    height: 18px;
    color: #7F887E;
  }

  .carousel .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%555B55' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .carousel .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%555B55' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }

  .carousel-item > img {
    /* Sets the overall height of the carousel */
    /* Allow a little more height on a smaller device to account for the summary text wrapping */
    height: 175px;
  }

  @media screen and (min-width: 768px) {
    .carousel-container {
      width: 640px;
    }

    /* Override the svg image size and colour */
    .carousel .carousel-control-prev-icon,
    .carousel .carousel-control-next-icon {
      width: 25px; /* increased from 20px */
      height: 25px;
    }

    .carousel-item > img {
      height: 155px; /* Sets the overall height of the carousel */
    }
  }
</style>

