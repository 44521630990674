<template>
  <div>
    <b-table v-if="values && values.length > 0" class="mb-0" head-variant="light"
             responsive hover small bordered show-empty
             :items="items" :fields="fieldDefinitions">
      <template #empty>
        {{$t('evidence.no-entries')}}
      </template>
    </b-table>
    <b-row v-else>
      <b-col md="3">
        {{$t('evidence.no-entries')}}
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { DetailsPanelField } from '@/types/data/detailsPanelField';
  import TextField from '@/components/evidence/fields/TextField.vue';
  import JsonTreeField from '@/components/evidence/fields/JsonTreeField.vue';
  import { PanelFieldType } from '@/types/enums/panelFieldType';
  import DateTimeHelper from '@/utils/DateTimeHelper';

  // Used for displaying both Comments and Bookmarks as Bookmark class inherits from Comment class
  @Component({
    components: {
      TextField,
      JsonTreeField
    }
  })
  export default class TablePanel extends Vue {
    @Prop() fields!: DetailsPanelField[];
    @Prop() values!: string[][];
    panelFieldType = PanelFieldType;
    fieldDefinitions: any[] = [];
    items: any[] = [];

    created() {
      this.prepareTheData();
    }

    prepareTheData() {
      this.fieldDefinitions = this.fields.map(x =>
        ({ key: x.displayName, sortable: true, thStyle: { 'min-width': x.columnMinWidthPx + 'px' } }));

      for (const row of this.values) {
        const item: any = {};
        for (let i = 0; i < this.fieldDefinitions.length; i++) {
          item[this.fieldDefinitions[i].key] = this.convertToDateIfApplicable(row[i]);
        }
        this.items.push(item);
      }
    }

    convertToDateIfApplicable(value: string): string {
      try {
        if (DateTimeHelper.isDate(value)) {
          return DateTimeHelper.getFormattedDate(value);
        }
        return value;
      } catch (err) {
        console.error(err);
        return value;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
