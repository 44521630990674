<template>
  <div class="home">
    <div v-if="shareDetails && !showError">
      <div class="row mt-3">
        <div class="col col-sm-9 justify-content-center align-self-end">
          <h1 v-if="shareDetails.evidenceItem.title" class="mx-0 mt-2 mb-1">{{shareDetails.evidenceItem.title}}</h1>
          <span class="mr-3"><strong>{{$t('evidence.media-type')}}:</strong> {{shareDetails.evidenceItem.mediaType}}</span>
          <span v-if="fileSize !== ''" class="mr-3"><strong>{{$t('evidence.file-size')}}:</strong> {{fileSize}}</span>
        </div>
        <div class="col-12 col-sm-3">
          <b-dropdown variant="secondary" right :popper-opts="popperOpts" class="mt-2 mr-0 float-right" v-if="shareDetails.evidenceItem.evidenceFiles.length > 0">
            <template slot="button-content">
              {{$t('evidence.downloads-button', { 'count': shareDetails.evidenceItem.evidenceFiles.length})}}
            </template>
            <b-dropdown-item v-for="file in shareDetails.evidenceItem.evidenceFiles" :key="file.fileId" :href="fileUrl(file)">
              {{$t('evidence.variant-types.'+file.variantName)}}
              ({{ file.fileExtension }}) <span v-if="showFileSize(file)">({{file.fileSize}})</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <EvidenceMedia :data="shareDetails.evidenceItem" :includeCommentsAndBookmarks="shareDetails.reportOptions.includeCommentsAndBookmarks" :showMediaTypeDisclaimer="shareDetails.showMediaTypeDisclaimer" v-on:getFileError="handleGetFileError"></EvidenceMedia>
      <EvidenceDetails :data="shareDetails" :includeCommentsAndBookmarks="shareDetails.reportOptions.includeCommentsAndBookmarks"></EvidenceDetails>
    </div>
    <div class="row mt-5 justify-content-center align-items-center" v-else-if="showError && unshared">
      <h2 class="ml-2">{{$t('evidence.loading-unshared')}}</h2>
      <p class="lead text-muted">{{$t('evidence.loading-unshared-details')}}</p>
    </div>
    <div class="row mt-5 justify-content-center align-items-center" v-else-if="showError && extracting">
      <h2 class="ml-2">{{$t('evidence.loading-extracting')}}</h2>
      <p class="lead text-muted">{{$t('evidence.loading-extracting-details')}}</p>
    </div>
    <div class="row mt-5 justify-content-center align-items-center" v-else-if="showError">
      <h2 class="ml-2">{{$t('evidence.loading-error')}}</h2>
      <p class="lead text-muted">{{$t('evidence.loading-error-details')}}</p>
    </div>
    <div class="row mt-5 justify-content-center align-items-center" v-else>
      <h1><font-awesome-icon icon="spinner" pulse /></h1><h2 class="ml-2">{{$t('evidence.loading')}}</h2>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator';
  import apiClient from '@/services/apiClient';
  import { ShareDetails } from '@/types/data/shareDetails';
  import EvidenceDetails from '@/components/evidence/EvidenceDetails.vue';
  import EvidenceMedia from '@/components/evidence/EvidenceMedia.vue';
  import { buildFileUrl } from '@/services/apiClient';
  import languageService from '@/services/languageService';
  import BaseView from '@/components/BaseView';
  import { EvidenceFile } from '@/types/data/evidenceFile';

  @Component({
    components: {
      EvidenceDetails,
      EvidenceMedia
    }
  })
  export default class Evidence extends BaseView {

    shareDetails: ShareDetails | null = null;
    showError: boolean = false;
    unshared: boolean = false;
    extracting: boolean = false;
    fileSize: string = '';
    popperOpts: any = {
      modifiers: {
        computeStyle: {
          gpuAcceleration: false
        }
      }
    };

    fileUrl(file: EvidenceFile) {
      return buildFileUrl(this.$route.params.id, file.fileId, true);
    }

    handleGetFileError() {
      this.showError = true;
    }

    created() {
      if (this.$route.query.error) {
        this.setErrorFlags(this.$route.query.error);
        return;
      }

      this.retrieveAndPopulate(this.$route.params.id).then(x => {
        this.shareDetails = x;

        if (this.shareDetails) {
          this.fileSize = this.getFileSize(this.shareDetails);

          if (this.shareDetails.evidenceItem.title) {
            document.title = languageService.t('page-title.evidence', { title: this.shareDetails.evidenceItem.title });
          }
        }
      });
    }

    async retrieveAndPopulate(id: string): Promise<ShareDetails> {
      this.shareDetails = null;
      this.showError = false;
      return apiClient.get(`share/${id}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.error('Unable to retrieve evidence data', error);
          this.setErrorFlags(error);
        });
    }

    setErrorFlags(error: any): void {
      this.showError = true;
      if (!error.response) {
        return;
      }
      const errorCode = Number(error.response.status);
      if (isNaN(errorCode)) {
        return;
      }

      this.unshared =
        errorCode === 451 ||  // Unavailable For Legal Reasons
        errorCode === 410;    // Gone (deleted)
      this.extracting =
        errorCode === 406;    // Not Acceptable
    }

    getFileSize(shareDetails: ShareDetails): string {
      let fileSize = '';

      if (shareDetails.evidenceItem.evidenceFiles &&
        shareDetails.evidenceItem.evidenceFiles.length > 0) {
          const primaryMediaFile = shareDetails.evidenceItem.evidenceFiles[0];
          if (primaryMediaFile && this.showFileSize(primaryMediaFile)) {
            fileSize = primaryMediaFile.fileSize;
          }
        }

      return fileSize;
    }

    showFileSize(file: EvidenceFile): boolean {
      return file.fileSize.split(' ')[0] !== '0';
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
