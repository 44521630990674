import _Vue from 'vue';
import moment from 'moment';
import MomentPluginConfig from './momentPluginConfig';

let Vue: typeof _Vue;
let i18n: any;

export default class MomentPlugin {

  static install(vue: typeof _Vue, options?: MomentPluginConfig): void {
    if (Vue && vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('already installed, Vue.use(MomentPlugin) should only be called once.');
      }
      return;
    }

    Vue = vue;

    this._initI18n(options);

    Object.defineProperty(Vue.prototype, '$moment', { value: moment });

    // very basic filter, should be ok for simple formating dates
    // will need to be expanded for heavier usage should that ever be required
    Vue.filter('moment', (date: string, format: string): string => {
      return moment(date).format(format);
    });

    Vue.filter('duration', (durationInSeconds: number): string => {
      return moment.utc().set({ hour: 0, minute: 0, second: durationInSeconds, millisecond: 0 }).format('HH:mm:ss');
    });
  }

  static _initI18n(options?: MomentPluginConfig) {
    if (!i18n && options && options.i18n) {
      i18n = options.i18n;
      moment.locale(i18n.locale); // keep momentjs in sync with i18n
    }

    const onLocaleChanged = () => {
      moment.locale(i18n.locale);
    };

    // i18n is being used for localization.
    if (i18n) {
      // listen to any changes made to the locale property in i18n
      i18n._vm.$watch('locale', onLocaleChanged);
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $moment: moment.Moment;
  }
}
