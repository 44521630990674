// Babel replaces imports of core-js to import only required for a target environment modules. (useBuiltIns: 'entry')
import 'core-js';

import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store';

// Bootstrap 4
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

// Font Awesome 5 Pro
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faPlusSquare,
  faMinusSquare,
  faChevronCircleLeft,
  faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faQuestionCircle,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faPlusSquare,
  faMinusSquare,
  faChevronCircleLeft,
  faExclamationCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Site CSS
import '@/sass/site.scss';

// Multi-lang
import LanguageService from '@/services/languageService';
import VueI18n from 'vue-i18n';
import MomentPlugin from '@/moment/momentPlugin';
Vue.use(VueI18n);

Vue.config.productionTip = false;

LanguageService.setup()
  .then(i18n => {
    Vue.use(MomentPlugin, { i18n });

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app');
  })
  .catch(error => {
    console.error(error);
    window.location.href = '/error';
  });
